import type { PaymentStatus } from '@shared/types/models'

export const FINAL_STATUSES: PaymentStatus[] = [
    'expired',
    'refunded',
    'voided',
    'declined',
    'approved',
] as const

export const BAD_STATUSES: PaymentStatus[] = [
    'declined',
    'expired',
    'voided',
] as const
